import React from "react"
import classes from "./FAQs.module.scss"
import Layout from "../components/layout"
import _ from "lodash"
import { graphql } from "gatsby"

import Accordion from "../components/accordion"

export default function FAQs({ data }) {
  const faqs = _.groupBy(data.allDatoCmsFaq.edges[0].node.questions, "category")
  return (
    <Layout pageTitle={"FAQ"}>
      <div className={classes.root}>
        <h3 className={classes.subtitle}>Customer</h3>
        <h1 className={classes.title}>Frequently Asked Questions</h1>

        <div className={classes.faqContainer}>
          {faqs["Customer"].map((item, index) => {
            const { question, answerNode, document } = item
            return (
              <div>
                {/* {document.url} */}
                <Accordion
                  title={question}
                  content={answerNode.childMarkdownRemark.html}
                  document={document ? document.url : "No document"}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allDatoCmsFaq {
      edges {
        node {
          questions {
            question
            document {
              url
            }
            category
            description
            answerNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
